import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/core/services';
import { UserDto, UserProfileService } from '../../../../core/backend';
import { ProfilePictureService } from '../../../../core/services/profile-picture.service';
import { LanguageSelectComponent } from '../language-select/language-select.component';

//TODO Remove/Refactor the hardcoded image format and image file size
const VALID_IMAGE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/jpg',
];
const MAX_IMAGE_SIZE_MB = 5;

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, LanguageSelectComponent, NgOptimizedImage],
})
export class AvatarComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  isAuthenticated$: Observable<boolean>;

  @Input() hasUploadButton: boolean = false;
  @Input() user: UserDto | undefined;
  @Input() userProfileImage: string | undefined | null;
  @Input() circleClasses: string = 'w-10';
  @Input() textClasses: string = '';

  private profilePictureSubscription: Subscription | undefined;

  constructor(
    public readonly authService: AuthService,
    private readonly userProfileService: UserProfileService,
    private readonly cdr: ChangeDetectorRef,
    private profilePictureService: ProfilePictureService,
    private logger: NGXLogger,
  ) {
    this.isAuthenticated$ = this.authService.isAuthenticated$();
    if (!this.user) {
      this.user = this.authService.currentUserValue!;
    }
    if (!this.userProfileImage && this.user.profileImageUuid) {
      this.getProfileAvatar();
    }
  }

  ngOnInit() {
    this.profilePictureSubscription =
      this.profilePictureService.profilePicture$.subscribe((picture) => {
        this.userProfileImage = picture;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    if (this.profilePictureSubscription) {
      this.profilePictureSubscription.unsubscribe();
    }
  }

  getProfileAvatar(): Subscription {
    return this.userProfileService
      .getUserProfileImage({ xTenantSlug: this.authService.currentTenantSlug })
      .subscribe((response: Blob) => {
        this.userProfileImage = URL.createObjectURL(response);
        this.profilePictureService.setProfilePicture(this.userProfileImage);
        this.cdr.detectChanges();
      });
  }

  changeProfilePicture(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement && inputElement.files && inputElement.files.length > 0) {
      const file: File = inputElement.files[0];

      if (!this.isValidImage(file)) {
        return;
      }

      this.uploadProfilePicture(file);
    }
  }

  private isValidImage(file: File): boolean {
    if (!VALID_IMAGE_TYPES.includes(file.type)) {
      alert('Only JPG, JPEG, PNG, and WEBP files are allowed.');
      return false;
    }

    if (file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
      alert('File size must be less than 5MB.');
      return false;
    }

    return true;
  }

  private uploadProfilePicture(file: File) {
    this.userProfileService
      .createUserProfileImage({
        xTenantSlug: this.authService.currentTenantSlug,
        file,
      })
      .subscribe({
        next: () => {
          this.authService.refresh().subscribe();
          this.getProfileAvatar();
          this.cdr.detectChanges();
        },
        error: (error) => {
          this.logger.error(
            'Error while setting profile picture: ',
            error.error?.message,
          );
        },
      });
  }
}

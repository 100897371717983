import { Routes } from '@angular/router';
import { authGuard } from 'src/core/guards';
import { environment } from '../../environments/environment';
import { appRoutesNames } from './app.routes.names';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: environment.homeComponent,
  },
  {
    path: appRoutesNames.AUTH,
    loadChildren: () =>
      import('./auth/auth.routes').then((module) => module.authRoutes),
  },
  {
    path: appRoutesNames.PROFILE,
    loadChildren: () =>
      import('./profile/profile.routes').then((module) => module.profileRoutes),
  },
  /*
  {
    path: appRoutesNames.DASHBOARD,
    canMatch: [authGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.routes').then(
        (module) => module.dashboardRoutes,
      ),
  },
  */
  /*
  {
    path: appRoutesNames.NEWS,
    canMatch: [authGuard],
    loadChildren: () =>
      import('./news/news.routes').then((module) => module.newsRoutes),
  },
  */
  {
    path: appRoutesNames.ASSESSMENTS,
    canMatch: [authGuard],
    loadChildren: () =>
      import('./assessments/assessments.routes').then(
        (module) => module.assessmentsRoutes,
      ),
  },
  {
    path: appRoutesNames.AGENT,
    canMatch: [authGuard],
    loadChildren: () =>
      import('./agent/agent.routes').then((module) => module.agentRoutes),
  },
  /*
  {
    path: appRoutesNames.USERS,
    canMatch: [authGuard],
    loadChildren: () =>
      import('./users/users.routes').then((module) => module.usersRoutes),
  },
  */
  {
    path: '**',
    loadChildren: () =>
      import('./not-found/not-found.routes').then(
        (module) => module.notFoundRoutes,
      ),
  },
];

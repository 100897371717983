export const environment = {
  name: 'development',
  production: true,
  homeComponent: 'agent',
  showEnvironmentInformation: false,
  newsLayoutColumns: 3,
  backendUrl: 'https://vincent.innovation-center.com/backend',
  incAssessmentServiceUrl: 'http://localhost:8420',
  incAgentServiceUrl: 'https://vincent.innovation-center.com/aiagent',
};

export * from './administrationTenants.service';
import { AdministrationTenantsService } from './administrationTenants.service';
export * from './agent.service';
import { AgentService } from './agent.service';
export * from './authentication.service';
import { AuthenticationService } from './authentication.service';
export * from './binaries.service';
import { BinariesService } from './binaries.service';
export * from './blogEntries.service';
import { BlogEntriesService } from './blogEntries.service';
export * from './blogs.service';
import { BlogsService } from './blogs.service';
export * from './groups.service';
import { GroupsService } from './groups.service';
export * from './userProfile.service';
import { UserProfileService } from './userProfile.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [AdministrationTenantsService, AgentService, AuthenticationService, BinariesService, BlogEntriesService, BlogsService, GroupsService, UserProfileService, UsersService];
